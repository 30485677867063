import { template as template_13a679e0dc0a4463a46e40454216e76d } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_13a679e0dc0a4463a46e40454216e76d(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
