import { template as template_338e79ad6b38437992853d5593870074 } from "@ember/template-compiler";
const WelcomeHeader = template_338e79ad6b38437992853d5593870074(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
