import { template as template_9db0ce48692e4b3b8253f7c0d70311e8 } from "@ember/template-compiler";
const FKLabel = template_9db0ce48692e4b3b8253f7c0d70311e8(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
